import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#0b1e9e',
      light: '#aabbff',
      lighter: '#adf7ee',
      dark: '#091A7A',
      darker: '#0a164e',
    },
    secondary: {
      main: '#b0a8ba',
      light: '#ece1f0',
      lighter: '#fdf7ff',
      dark: '#9d94a8',
      darker: '#887e94',
    },
    text: {
      primary: '#525860',
      secondary: '#a7a7a7',
    },
    success: {
      main: '#2db54b',
    },
    warning: {
      main: '#b8ba2d',
    },
    error: {
      main: '#b82c33',
    },
  },
  spacing: (value) => value * 8, // 8px grid system
});

export {customTheme};
