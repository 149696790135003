import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers : { 
    // 'Content-Type': 'multipart/form-data',
  }
});

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.log("axios" , error)
//     // return Promise.reject(error);
//     return error
//   }
// );

export default axiosInstance;
