import { lazy,Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// import { Loadable } from 'routes/paths';
import LoadingScreen from 'components/LoadingScreen';

export const Loadable = (Component) => (props) => {
    return (
      <Suspense fallback={<LoadingScreen/>}>
        <Component {...props} />
      </Suspense>
    );
  };

const NavBarHorizontal = Loadable(lazy(() => import('layout/NavBarHorizontal')));
const NavBarVertical = Loadable(lazy(() => import('layout/NavBarVertical')));

export default function NavBarLayout() {

    return (
    <div>
        <NavBarHorizontal />
        <Outlet />
    </div>
    )
}