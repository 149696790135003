import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from 'redux_data/store';

const slice = createSlice({
  name: 'admin', // This is the name of the slice
  initialState: {
    hasError: null,
    isLoading: false,
    isLoadingDetail: false,
    datas: [],
    data: {},
  },
  reducers: {
    noError(state) { // NO ERROR
      state.hasError = false;
    },
    unsetError(state) { // UNSET ERROR
      state.hasError = null;
    },
    hasError(state, action) { // HAS ERROR
      state.isLoading = false;
      state.hasError = JSON.stringify(action.payload);
    },
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    startLoadingDetail: (state, action) => {
      state.isLoadingDetail = true;
    },
    setDatas: (state, action) => {
      state.isLoading = false;
      state.datas = action.payload;
    },
    setData: (state, action) => {
      state.isLoadingDetail = false;
      state.data = action.payload;
    },
  },
});

export const { 
    noError , 
    unsetError , 
    hasError, 
    startLoading , 
    startLoadingDetail , 
    setDatas , 
    setData ,
} = slice.actions;

const endpoint = "/admin"

export function searchData({ data = {} , params = {} } = {data: {} , params: {}}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let endpointURL = `${endpoint}/search`;
      Object.keys(params).forEach(key => {
        if(endpointURL.includes("?")){
          endpointURL = `${endpointURL}&${key}=${params[key]}`;
        }else{
          endpointURL = `${endpointURL}?${key}=${params[key]}`;
        }
      })
      // console.log("data",data);
      const response = await axios.post( endpointURL , data )?.then( resp => resp?.data);
      // console.log("searchData", response);
      if(response?.success){
        dispatch(slice.actions.setDatas(response.data));
      }else{
        dispatch(slice.actions.hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(slice.actions.hasError(error.message));
      }else{
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function addData({formData}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${endpoint}`, formData )?.then( resp => resp?.data);
      // console.log("addData", response);
      if(response?.success){
        dispatch(slice.actions.noError());
        dispatch(searchData());
      }else{
        dispatch(slice.actions.hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(slice.actions.hasError(error.message));
      }else{
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function editData({id, formData, encryptedPin}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if(encryptedPin){ axios.defaults.headers.common.pin = encryptedPin; }
      const response = await axios.patch(`${endpoint}/${id}`, formData )?.then( resp => resp?.data);
      // console.log("editData", response);
      if(response?.success){
        dispatch(slice.actions.noError());
        dispatch(searchData());
      }else{
        dispatch(slice.actions.hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(slice.actions.hasError(error.message));
      }else{
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function deleteData({list_id , encryptedPin}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(list_id , encryptedPin);
      if(encryptedPin){ axios.defaults.headers.common.pin = encryptedPin; }
      const response = await axios.put(`${endpoint}/` , {id:list_id});
      if(response?.data?.success){
        dispatch(slice.actions.noError())
        dispatch(searchData());
      }else{
        dispatch(slice.actions.hasError(response?.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.response?.data));
    }
  };
}



export default slice.reducer;
