import { jwtDecode } from 'jwt-decode';
// routes
// import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  if(decoded?.exp){
    return (decoded?.exp > currentTime)
  }else if(decoded?.iat > 0){
    return true;
  };
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    // eslint-disable-next-line no-alert
    alert('Token expired');

    localStorage.removeItem('accessToken');

    // window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~5 days by minimals server
    if(exp){
      handleTokenExpired(exp);
    }
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const getSession = () => {
  let userData;
  try {
    const accessToken = localStorage.getItem('accessToken');
    userData = decodeJWT(accessToken)
  } catch (error) {
    console.log("GET SESSION ERROR" , error);
  }
  return userData;
};

const decodeJWT = (accessToken) => {
  try {
    const payload = accessToken.split('.');
    const user = JSON.parse(atob(payload[1]));
    return user;
  } catch (error) {
    return null
  }
}

export { isValidToken, setSession, decodeJWT , getSession };
