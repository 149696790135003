import PropTypes from 'prop-types';
import { useState, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
// import useAuth from '../hooks/useAuth';
// pages
// import Login from '../pages/auth/Login';
// components
import LoadingScreen from 'components/LoadingScreen';
import {PATH} from 'routes/paths';
import { AuthContext } from "context/AuthContext";

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  // const navigate = useNavigate();

  const { isAuthenticated, isInitialized } = useContext(AuthContext);

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }
  if (isAuthenticated) {
    // console.log("AUTH");
    return <Navigate to={PATH.root} />;
    // if (pathname !== requestedLocation) {
    //   setRequestedLocation(pathname);
    // }
    // return <Login />;
  }

  
  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
