import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import { GoogleReCaptchaProvider, } from 'react-google-recaptcha-v3';
import { Provider as ReduxProvider } from 'react-redux';
import { SnackbarProvider } from 'notistack'
import CssBaseline from '@mui/material/CssBaseline';
import { customTheme } from './config/Palette';
// import NotistackProvider from 'components/NotistackProvider';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from 'redux_data/store';
import {AuthProvider} from 'context/AuthContext';
import {reCaptchaV3SiteKey} from './config';
import * as serviceWorker from './serviceWorker'; // Change this line if your service worker file is named differently
import Router from 'routes';

const helmetContext = {};
const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(<GoogleReCaptchaProvider reCaptchaKey={reCaptchaV3SiteKey}>
  <AuthProvider>
    <HelmetProvider context={helmetContext}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor} >
          <ThemeProvider theme={customTheme}>
            <CssBaseline />
            <SnackbarProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </SnackbarProvider>
          </ThemeProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </AuthProvider>
</GoogleReCaptchaProvider>);

serviceWorker.register();
